import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="intro">
    <h2>Colonel Trautman Sir, do we get to win this time?</h2>
      <div style={{ maxWidth: `300px`, margin: `auto` }}>
        <Image />
      </div>
    </div>
  </Layout>
)

export default IndexPage
